import { Col, Row } from "antd";
import { useState } from "react";
import { TFunction, withTranslation } from "react-i18next";
import elevenStart from "../../assets/elevenStart.png";
import francedrapeau from "../../assets/france_round_icon_640.png";
import usadrapeau from "../../assets/united_states_of_america_round_icon_640.png";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";

import i18n from "i18next";
import {
  Chat,
  Empty,
  Extra,
  FooterContainer,
  FooterSection,
  Label,
  Language,
  LanguageSwitch,
  LanguageSwitchContainer,
  Large,
  LogoContainer,
  NavLink,
  Para,
  Title,
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: { t: TFunction }) => {
  const [visible, setVisibility] = useState(false);
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  const scrollTo = (id: string) => {
    const element = document.getElementById(id) as HTMLDivElement;
    element.scrollIntoView({
      behavior: "smooth",
    });
    setVisibility(false);
  };

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
              <Language>{t("Contact")}</Language>
              <a href="mailto:contact@elevenstart.com">
                <Chat>{t("Tell us everything")}</Chat>
              </a>
              <Para>
                {t(`Do you have any question? Feel free to reach out.`)}
              </Para>
              <a href="mailto:contact@elevenstart.com">
                <Chat>{t(`Let's Chat`)}</Chat>
              </a>
            </Col>
            <Col lg={8} md={8} sm={12} xs={12}>
              <Title>{t("Explore")}</Title>
              <Large to="/" onClick={() => scrollTo("contact")}>{t("Resources")}</Large>
              <Large to="/" onClick={() => scrollTo("contact")}>{t("Events")}</Large>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <Empty />
              <Large to="/privacy">{t("Privacy Policy")}</Large>
              <Large to="/cgu">{t("Termes of Use")}</Large>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
              <Empty />
              <Language>{t("Address")}</Language>
              <Para>BP7298</Para>
              <Para>C’165 Missessin</Para>
              <Para>Cotonou Bénin</Para>
            </Col>
            <Col lg={8} md={8} sm={12} xs={12}>
              <Title>{t("Company")}</Title>
              <Large to="/" onClick={() => scrollTo("contact")}>{t("Partners")}</Large>
              <Large to="/" onClick={() => scrollTo("contact")}>{t("Mentors")}</Large>
              <Large to="/" onClick={() => scrollTo("contact")}>{t("Community")}</Large>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <Label htmlFor="select-lang">{t("Language")}</Label>
              <LanguageSwitchContainer>
                <LanguageSwitch onClick={() => handleChange("fr")}>
                  <img src={francedrapeau} width="40px" height="30px" />
                </LanguageSwitch>
                <LanguageSwitch onClick={() => handleChange("en")}>
                  <img src={usadrapeau} width="40px" height="30px" />
                </LanguageSwitch>
              </LanguageSwitchContainer>
            </Col>
          </Row>
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "3rem" }}
          >
            <NavLink to="/">
              <LogoContainer>
                <img src={elevenStart} width="60px" height="60px" />
              </LogoContainer>
            </NavLink>
            <FooterContainer>
              {/* <SocialLink
                href="https://github.com/Adrinlol/create-react-app-adrinlol"
                src="github.svg"
              /> */}
              {/* <SocialLink
                href="https://twitter.com/Adrinlolx"
                src="twitter.svg"
              /> */}
              <SocialLink
                href="https://www.linkedin.com/company/eleven-start/"
                src="linkedin.svg"
              />
              {/* <SocialLink
                href="https://medium.com/@lashakakabadze/"
                src="medium.svg"
              /> */}
              {/* <a
                href="https://ko-fi.com/Y8Y7H8BNJ"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  height="36"
                  style={{ border: 0, height: 36 }}
                  src="https://storage.ko-fi.com/cdn/kofi3.png?v=3"
                  alt="Buy Me a Coffee at ko-fi.com"
                />
              </a> */}
            </FooterContainer>
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
